import React from 'react'
import './Info2.css'
import img from '../../img/Recurso 91.png'
import img1 from '../../img/Recurso 91.png'

const Info2 = () => {
  return (
    <div className="info23">
      <div>
       
        <img className="imginfo23" src={img} alt=""/>
        <img className="imginf223" src={img1} alt="" />
      </div>
    </div>
  )
}

export default Info2