import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import CardDetalles from '../../components/RecursosCard/CardDetalles'
import { Context } from '../../Store'

const RecDetalles = () => {
    const {recursos} = useContext(Context)
    const {id} = useParams()
    const cardDetalle = recursos.filter((recurso)=>recurso.id == id)
    console.log(cardDetalle)

  return (
    <>
     <div className='container'>
    <div className='d-flex flex-wrap justify-content-around'>
    {
      cardDetalle.length > 0? (
        cardDetalle.map(recurso => (
          <CardDetalles key={recurso.id} {...recurso} />
        ))
      ) : (<p>cargando</p>)
    }
    </div>
    </div>
    <div className='footerecursos'>
           <Footer/>
           </div>
    </>
  )
}

export default RecDetalles