import React from 'react'
import img from "../../img/Logo Zoom.png";
import img1 from "../../img/Logo rec.png";
import img2 from "../../img/Logo PDF.png"
import img5 from "../../img/Destello proposito.png"
import img3 from "../../img/Recurso 19.png"
import './Clubmid2.css'

const Clubmid2 = () => {
  return (
    <div className="md2   ">
    <img className="strella" src={img5} alt=""/>
  <h2 className="text-center tx2 mb-5">
    ¿Qué incluye el Club de lectura?
  </h2>
  <div className="container cardView d-flex flex-wrap justify-content-center">
    <div className="">
      <div className="card border-0 cardss" style={{ width: "280px" }}>
        <div className="card-top bgc2 ">
          <img
            src={img}
            className="card-img-top imglogo"
            alt="..."
          />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mb-3 cardtitulo2">
            Acceso al zoom del club de lectura
          </h5>
          <p className="card-text p mb-3 ct">
            Transmitido por la App Zoom, 45 min de lectura y 15 min de meditación  guiada
          </p>
        </div>
      </div>
    </div>
    <div className="">
      <div className="card border-0 cardss" style={{ width: "280px" }}>
        <div className="card-top bgc2">
          <img src={img1} className="card-img-top imglogo" alt="..." />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mb-3 cardtitulo2">
            Grabación de las lecturas y meditación 
          </h5>
          <p className="card-text p mb-3 ct">
            Se enviará a tu correo inscrito luego de finalizar la sesion de lectura y meditación 

          </p>
        </div>
      </div>
    </div>
    <div className="">
      <div className="card border-0 cardss" style={{ width: "280px" }}>
        <div className="card-top bgc2">
          <img src={img2} className="card-img-top imglogo1" alt="..." />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mb-3 cardtitulo2">
            Libro en PDF
          </h5>
          <p className="card-text p mb-3 ct">
           Contiene el libro en formato PDF para poder acompañar el momento de la lectura.
            
          </p>
        </div>
      </div>
    </div>
    
  </div>
</div>
  )
}

export default Clubmid2