import React from 'react'
import './Clubtop2.css'

const Clubtop2 = () => {
  return (
    <div className="bgtop2">
      <div className="container">
        <p className="ptop2">
          "Un grupo de gente que se reúne en un estado de presencia genera un
          campo de energía colectiva de gran intensidad. No solo eleva el grado
          de presencia de cada miembro del grupo sino que también ayuda a
          liberar la conciencia humana de su estado habitual de dominio de la
          mente."
        </p>
        <h4 className="h4top2 mt-5">Eckhart Tolle</h4>
      </div>
    </div>
  )
}

export default Clubtop2