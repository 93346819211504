import React from 'react'
import Info from '../../components/info/Info'
import Imginfo from '../../components/infoimg/Imginfo'
import Mid from '../../components/Mid/Mid'
import Mid2 from '../../components/mid2/Mid2'
import Mid3 from '../../components/mid3/Mid3'
import Mid4 from '../../components/mid4/Mid4'
import Top from '../../components/Top/Top'
import Form2 from '../../components/Form copy/Form2'
import Inscribirse from '../../components/Inscribirse/Inscribirse'
import Pagoinfo from '../../components/Pagoinfo/Pagoinfo'
import Footer from '../../components/Footer/Footer'
import './Home.css'

const Home = () => {
  return (
    <div>
        <Top/>
        <Info/>
        <Imginfo/>
        <Mid/>
        <Mid2/>
        <Mid3/>
        <Form2/>
        <Pagoinfo/>
        <Footer/>
    </div>
  )
}

export default Home