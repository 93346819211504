import React from 'react'
import './EncuentroComent.css'
import img from '../../../img/Recurso 131-.png'
import img2 from '../../../img/Recurso 152-.png'
import img3 from '../../../img/Recurso 153-.png'
import img4 from '../../../img/Recurso 154-.png'
import img5 from '../../../img/Recurso 155-.png'
import img6 from '../../../img/Recurso 156-.png'
import img7 from '../../../img/Recurso 157-.png'
import img8 from '../../../img/Recurso 158-.png'



const EncuentroComent = () => {
  return (
    <div className='bgcomentencuentro'>
        <div>
            <h3 className='h3comentencuentro'>Por aquí te  comparto algunos comentarios de quienes hacen parte de los encuentros:</h3>
        </div>
        <div>
        <div className="">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={img} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img2} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img3} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img4} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img5} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img6} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img7} className="  imgcomentencuentro" alt="..."  />
          </div>
          <div class="carousel-item ">
            <img src={img8} className="  imgcomentencuentro" alt="..."  />
          </div>
         
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
        </div>
    </div>
  )
}

export default EncuentroComent