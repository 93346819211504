import React from 'react'
import './Clubmid.css'
import img from "../../img/Recurso 85.png";


const Clubmid = () => {
  return (
    
    <div className="bgmidcita">
    <div className="d-flex container">
      <div>
        <img className="imgmid" src={img} alt="" />
      </div>
      <div className="midtext">
        <h4 className="h4mid">&#8213; Estamos leyendo</h4>
        <h3 className="midh2">Un puente a la realidad</h3>
        <h2 className="midh23">Autor: Sergi Torres</h2>
        <p className="pmid">
        "Primero nos dimos cuenta de que las cosas no pasaban por
          casualidad. Luego, de que ocurrían para que pudiéramos aprender y
          evolucionar. Ahora es el momento de reconocer que el amor y sus
          consecuencias son lo único que existe y que el resto, el conflicto,
          el sufrimiento y la soledad son meras ilusiones de la conciencia
          humana. ¿Estas preparado para dar el paso? Olvídate de lo que ves,
          piensas, sientes o crees saber, pues todo eso es temporal, limitado
          y separado de tu verdadera naturaleza. Atrévete a ir más allá de tu
          misma identidad para que aflores a tu conciencia la belleza de la
          realidad. Deja que emerja la mente plena, eterna y universal.
          Atrévete a cruzar el puente a la Realidad y todo cuanto necesitas te
          vendrá dado." <br></br> Gaspar Hernandez
        </p>
       
        
        <p className="pmid">
        Escritor, Autor de más de 5 libros, conferencista en Europa,
          Latinoamérica y Estados Unidos. Impulsor de una nueva conciencia y
          dedicado al constante auto indagación sobre lo que es ser humano.

        </p>
      </div>
    </div>
  </div>
  )
}

export default Clubmid