import React from "react";
import "./Info.css";
import img from "../../img/Recurso 100.png";
import img2 from '../../img/Recurso 139.png'

const Info = () => {
  return (
    <div className="bginfo">
      <div className="d-flex justify-content-center allinfo">
        <div className="infotext">
          <h4 id="sobre">&#8213; Sobre mi</h4>
          <h2 className="h2info">"El momento de tomar acción es AHORA"</h2>
          <br></br> <br></br>
          <p className="pinfo" >
            Soy Lisset Albarracin y el propósito de mi corazón es acompañarte a
            verte y vivir tu vida de otra manera, esta manera no es otra que el
            Amor. Toda esta aventura de seguir mi propósito inició cuando emigré
            a Colombia, todo lo que representa este cambio me llevó a un
            profundo cuestionamiento que inició con dos preguntas: <br></br><br></br> 
            ¿Quién soy? ¿Cuál es mi propósito en la vida?<br></br> <br></br>  Y como
            respuesta, la vida me permitió profundizar en mi auto conocimiento e
            ir de la mano con la espiritualidad, aspectos bases de mi
            transformación, pues a partir de ese momento lo que llegué a
            percibir como “lo peor que podría pasarme” con respecto a la
            migración, se transformó en la experiencia perfecta que necesitaba
            vivir para conocerme, amarme, aceptarme y seguir la voz de mi
            corazón. <br></br> <br></br>  Hoy en día vivo en un tercer país, amo mi proceso
            de adaptación y celebro el cambio en mi vida. Hoy en día vivo mi
            propósito y me siento agradecida y feliz de poder vivirlo.
          </p>
          <br></br> 
          <div className="tabs" id="tabid">
            <div className="tab-container">
              <div id="tab2" className="tab">
                <a className="tabinfo hover-underline-animation " href="#tab2"> Actualidad</a>
                <div className="tab-content">
                  
                  <p className="pinfo">
                    Actualmente me dedico a darle vida a mi propósito,
                    construyendo mi comunidad, realizando encuentros de
                    consciencia, creando grupos de lectura y en el
                    acompañamiento individual en psicoterapia.<br></br> <br></br> Desde que inicié
                    la carrera de psicología, la mejor y mas retadora práctica
                    que he tenido ha sido mi propia experiencia personal, mi
                    propio deseo por verme, relacionarme y vivir la vida de otra
                    manera. Toda la experiencia adquirida y sobre todo el
                    trabajo que a diario realizo conscientemente en mi misma, me
                    lleva a seguir mi propósito
                  </p>
                </div>
              </div>
              <div id="tab1" className="tab">
                <a className="tabinfo hover-underline-animation " href="#tabid">Mi información</a>
                <div className="tab-content">
                  
                  <p className="pinfo">
                    Recibí el titulo de psicóloga en el año 2013, a partir de
                    allí inicia mi trabajo en psicoterapia individual, me formé
                    en Psicología hospitalaria y de la salud, certificada como
                    acompañante en expansión de conciencia y recientemente
                    culminé el Máster en psicoterapia del bienestar emocional. <br></br> <br></br>
                    He vivido experiencias laborales en organizaciones
                    internacionales, también en el área publica y privada,
                    experiencias con las que me siento profundamente agradecida,
                    pues el aprendizaje obtenido en cada una me ha traído hasta
                    aquí.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h4 className="h4info">Acompañarte a verte y vivir tu vida de otra manera. </h4>
        </div>
        <div className="imginf">
          <img className="imginfo" alt="" src={img}></img>
          <img className="imginfo2" alt="" src={img2}></img>
        </div>
      </div>
    </div>
  );
};

export default Info;
