import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { Context } from "../../Store";
import "./Paypal.css";

const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

const Paypal = () => {
  const { setErrorMessage, setOrderID, setSuccess, pricechange, price } = useContext(Context);
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            
            amount: {
              currency_code: "USD",
              value: 11,
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  return (
    <div className="container paypalbody">
      <h3 className="textinf mb-3">
        Primer paso: Paga el evento para poder obtener el orderID
      </h3>
      <h3 className="textinf mb-3">
      ¿Que deseas pagar?
      </h3>
      <select onChange={pricechange} name="select">
      
        
        <option value='19' >Encuentro de consciencia</option>
      </select>
      
      {/* 

      --Opciones de pago para paypal--
      <option value='60'>Sesión de psicoterapia</option>
      <option value="11">
        Grupo de lectura
        </option>
        <option value="150">Paquete de sesiones de psicoterapia</option>

        -- 
      <button type="button" className=" border-0" onClick={() => setShow(true)}>
               Pagar ahora
             </button>
         {show?<PayPalButton
          
           createOrder={createOrder}
           onApprove={onApprove}
           className='paypalboton'
           onError={onError}
         />:''} */}
      <PayPalButton
        createOrder={createOrder}
        onApprove={onApprove}
        className="paypalboton"
        onError={onError}
      />
    </div>
  );
};

export default Paypal;
