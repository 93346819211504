import axios from "axios";
import { createContext, useEffect, useState } from "react";



export const Context = createContext(null)
const UserProvider = ({children}) => {
  const [recursos, setRecursos] = useState([]);
  const [comentarios, setComentarios] = useState([])
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [price, setPrice] = useState(0)
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
    const [userinfo, setUserinfo] = useState({
        nombre: "",
        email: "",
        telefono: "",
        comentario: "",
        orderid: "",
        paypal:'',
        zelle:'',
        bizum:'',
        wise:'',
        binance:''
      });
      const [rec, setRec] = useState({
        titulo: "",
        descripcion: "",
        imagen_path: "",
        audio_path: "",
      });
     
      
console.log(rec)
      const pricechange = (e) => {
        setPrice(e.target.value)
      }

      const handleChange = (e) => {
        setUserinfo({
          ...userinfo,
          [e.target.name]: e.target.value,
        });
      };

   

      const handleChangerecursos = (e) => {
        setRec({
          ...rec,
          [e.target.name]: e.target.value,
        });
      };

      const info = rec;

      console.log(price)
      const data = userinfo;

     
 
      const crearDatos = async () => {
        const response = await axios.post(`https://www.back.lissetalbarracin.com/email`, data);
        
        alert("Registro realizado exitosamente, revisa tu correo");
      };
      const crearDatos2 = async () => {
        const response = await axios.post(`https://www.back.lissetalbarracin.com/hom`, data);
        
        alert("Registro realizado exitosamente, revisa tu correo");
      };
      const obtenerRecursos = async () => {
        const response = await axios.get(`https://back.lissetalbarracin.com/recursosAudios`);
        const data = response.data
        setRecursos(response.data);
        console.log(response.data);
      };

      const enviarRecursos = async () => {
        const response = await axios.post(`https://back.lissetalbarracin.com/recursosAudios`, info);
        alert("Registro realizado exitosamente");
      }

      const crearDatos3 = async () => {
        const response = await axios.post(`https://www.back.lissetalbarracin.com/enc`, data);
        
        alert("Registro realizado exitosamente, revisa tu correo");
      };
      

      useEffect(() => {
        if (success) {
          alert(`Pago realizado! tu ${orderID} anotalo para colocarlo en el registro`);
        } else {
          
        }
      },
      [success]
    );
    useEffect(()=>{
      obtenerRecursos();
      
    },{})
   
    const order = () =>{
      alert(`${orderID}`)
    }


    return (
        <Context.Provider value={{
            crearDatos,
            crearDatos2,
            handleChange,
            crearDatos3,
        show,
        obtenerRecursos,
        setErrorMessage,
        setOrderID,
        setShow,
        setSuccess,
        success,
        orderID,
        ErrorMessage,
        order,
        price,
        setPrice,
        pricechange,
        recursos,
        handleChangerecursos,
        enviarRecursos,
        setComentarios,
        comentarios,
        
        }}>
            {children}
        </Context.Provider>
    )
}

export default UserProvider