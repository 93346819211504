import React from 'react'
import './Midcitas2.css'

const Midcitas2 = () => {
  return (
    <div className='bgmidcitas2'>
      <div className='textmidcitas2'>
      <h2 className='h2midcitas2'>"El sentir es un miedo que por regalo buscamos distraer"</h2>
            <h3 className='h3midcitas2'>Lisset Albarracin</h3>
      </div>
            
    </div>
  )
}

export default Midcitas2