import React, { useContext } from "react";
import "./Form2.css";
import img1 from "../../img/Recurso 105.png";
import img2 from "../../img/Recurso 106.png";
import img3 from "../../img/Recurso 123.png";
import img4 from "../../img/Recurso 108 - copia.png";
import img5 from "../../img/Recurso 75.png";
import img6 from "../../img/Recurso 118.png";

import { Context } from "../../Store";

const Form = () => {
  const { handleChange, crearDatos2 } = useContext(Context);
  return (
    <div className="bgform2">
      <div className="d-flex al ">
        <img className="img62" src={img6} alt="" />
        <div className="textform2">
          <h3 className="h3form2">&#8212;Como deseas contactarme</h3>
          <h1 className="h1form2">Estaré feliz de conocerte </h1>
        </div>
      </div>
      <div className="d-flex allform2">
        <div className="form">
          <div class="container">
            <label> Nombre y apellido </label>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre y Apellido"
              size="15"
              onChange={handleChange}
              required
            />
            <label>telefono :</label>
            <input
              type="text"
              name="telefono"
              placeholder="Teléfono"
              size="10"
              onChange={handleChange}
              required
            />
            <label for="email">Email</label>
            <input
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleChange}
              required
            />
            Comentarios:
            <textarea
              cols="80"
              rows="5"
              placeholder="Comentarios"
              onChange={handleChange}
              name="comentarios"
              required
            ></textarea>
            <button type="submit" class="btn botonform" onClick={crearDatos2}>
              Enviar
            </button>
          </div>
        </div>
        <div className="imgsec2">
          <img className="imgform12 " src={img1} alt="" />
          <img className="imgform22 mt-5" src={img2} alt="" />
          <img className="imgform32 mt-5" src={img3} alt="" />
          <img className="imgform42 mt-5" src={img4} alt="" />
        </div>
      </div>
      <img className="imgform52" src={img5} alt="" />
    </div>
  );
};

export default Form;
