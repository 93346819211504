import React from 'react'
import './Mid2.css'

const Mid2 = () => {
  return (
    <div className='bgmid2'>

    </div>
  )
}

export default Mid2