import React from 'react'
import './Midcitas.css'
import img from "../../img/Recurso 119.png";
import img2 from '../../img/Recurso 109.png'

const Midcitas = () => {
  return (
    <div className="bgmidcitas">
      <div className="d-flex container infgcitas">
      <div>
            <img className="stmidcitas" src={img2} alt='' />
          
          
        </div>
        <div className="textmidcitas">
          <h4>&#8213; Con amor</h4>
          <h2 className="h2midcitas">Te acompaño en tu proceso </h2>
          <h3 className="h3midcitas">Psicoterapia online</h3>
          <p className="pmidcitas">
            La psicoterapia online es completamente efectiva, el objetivo de la
            sesión es el profundo autoconocimiento, lo que te permite conocer y
            aceptar tus emociones, sanar tu historia personal, tu forma de
            percibir el mundo y así conectar con tu mejor versión una que tiene
            como base, el amor.
            <br></br>
            <br></br>A mi consulta llegan personas que como tú y como yo
            eligieron sanar y vivir su vida de otra manera, los casos más
            frecuentes que acompaño son:
            <div className="d-flex txt">
              <div className="mt-4">
                <p>- Duelo migratorio</p>
                <p>- Ansiedad</p>
                <p>- Crisis de pánico</p>
                <p>- Tristeza profunda</p>
              </div>
              <div className="m-4">
                <p>- Estrés</p>
                <p>- Crisis de identidad</p>
                <p>- Relaciones basadas en el apego</p>
                <p>- Expansión de conciencia.</p>
              </div>
            </div>
          </p>
        </div>
        
      </div>
    </div>
  )
}

export default Midcitas