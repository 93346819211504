import React from 'react'
import './Encuentrotop.css'

const Encuentrotop = () => {
  return (
    <div className='bgEncuentrotop'>
        <h3 className='container text-center h3topencuentro'>
        Quiero darte las gracias por estar aquí.<br></br><br></br>
        Quizás ya habrás escuchado que nada es  casual; por esto, sé que tenerte aquí es un regalo
        </h3>
    </div>
  )
}

export default Encuentrotop