import React from 'react'
import './Encuentromid5.css'

const Encuentromid5 = () => {
  return (
    <div className='bgmid5encuentro'>
        <h2 className='h2mid5encuentro'>
        Solo me queda darte la bienvenida a este espacio y  recordarte que vamos directo al corazón! 
        </h2>
    </div>
  )
}

export default Encuentromid5