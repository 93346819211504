import {  Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Consulta from "./pages/Citas/Consulta";
import Club from "./pages/Club/Club";
import Encuentro from "./pages/Encuentro/Encuentro";

import Home from "./pages/Home/Home";
import Inscribirse from "./pages/Inscribirse/Inscribirse";
import RecDetalles from "./pages/RecDetalles/RecDetalles";
import Recursos from "./pages/Recursos/Recursos";
import ResEnc from "./pages/ResEnc/ResEnc";

function App() {
  return (
    <>
    <Navbar/>
    <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/citas" element={<Consulta />} />
          <Route exact path="/club" element={<Club />} />
          <Route exact path="/registro" element={<Inscribirse />} />
          <Route exact path="/recursos" element={<Recursos />} />
          <Route exact path="/detalles/:id" element={<RecDetalles />} />
          <Route exact path="/encuentro" element={<Encuentro />} />
          <Route exact path="/registroencuentros" element={<ResEnc />} />
          
    </Routes>
    </>
  );
}

export default App;
