import React, { useContext } from 'react'
import Footer from '../../components/Footer/Footer'
import RecursosCard from '../../components/RecursosCard/RecursosCard'
import { Context } from '../../Store'
import './Recursos.css'

const Recursos = () => {
    const {recursos} = useContext(Context)
  return (
    <>
    <div>
              <div className='d-flex flex-wrap justify-content-around mb-5'>
            {
              recursos.length > 0?(
                recursos.map((vape)=>(
                  <RecursosCard key={vape.id} {...vape} vape={vape}/>
                ))
              ) : (<p>cargando</p>)
            }
            </div>
            
           </div>
           <div className='footerecursos'>
           <Footer/>
           </div>
           
           </>
  )
}

export default Recursos