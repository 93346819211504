import React from "react";
import "./Encuentromid4.css";
import img9 from "../../../img/Recurso 125-.png";
import img from "../../../img/Logo Zoom.png";
import img1 from "../../../img/Logo rec.png";
import img2 from "../../../img/Logo PDF.png"
import img5 from "../../../img/Destello proposito.png"
import img3 from "../../../img/Recurso 19.png"

const Encuentromid4 = () => {
  return (
    <div className="bgmid4encuentro">
      <div className="container d-flex">
        <div>
          <h3 className="h3mid4encuentro">
            Siento mucho amor por este espacio que se ha sostenido con la
            intención de todos, hay una energía fuerte y poderosa en este
            espacio y se muestra a través de cada intervención, de cada
            ejercicio y en cada meditación durante el encuentro.
          </h3>
        </div>
        <div>
          <img className="imgmid4encuentro" src={img9} alt="" />
        </div>
      </div>
      <div className="md2 quemid2encuentro  ">
    <img className="strella" src={img5} alt=""/>
  <h2 className="text-center tx2 mb-5">
    ¿Qué incluye el espacio de consciencia?
  </h2>
  <div className="container cardView d-flex flex-wrap justify-content-center">
    <div className="">
      <div className="card border-0 cardss" style={{ width: "280px" }}>
        <div className="card-top bgc2 ">
          <img
            src={img}
            className="card-img-top imglogo"
            alt="..."
          />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mb-3 cardtitulo2">
            Acceso al encuentro en vivo
          </h5>
          <p className="card-text p mb-3 ct">
            Transmitido por la App Zoom, en un tiempo aproximado de 1 h 30 minutos.
          </p>
        </div>
      </div>
    </div>
    <div className="">
      <div className="card border-0 cardss" style={{ width: "280px" }}>
        <div className="card-top bgc2">
          <img src={img1} className="card-img-top imglogo" alt="..." />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mb-3 cardtitulo2">
            Grabación del encuentro
          </h5>
          <p className="card-text p mb-3 ct">
            Se enviará a tu correo inscrito luego de finalizar el encuentro.

          </p>
        </div>
      </div>
    </div>
    <div className="">
      <div className="card border-0 cardss" style={{ width: "280px" }}>
        <div className="card-top bgc2">
          <img src={img2} className="card-img-top imglogo1" alt="..." />
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mb-3 cardtitulo2">
            Pdf con ejercicio de auto indagación
          </h5>
          <p className="card-text p mb-3 ct">
           Contiene la información y los ejercicios que serán utilizados el dia del encuentro.
            
          </p>
        </div>
      </div>
    </div>
    
  </div>
</div>
    </div>
  );
};

export default Encuentromid4;
