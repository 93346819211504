import React from 'react'
import './FormComent.css'

const FormComent = (props) => {
    const {Nombre, titulo, comentario} = props
  return (
    <div className='contain mb-5 mt-5'>
       <div className=''>
       <h2 className='h2coment'>{Nombre}</h2>
        <p className='pcoment'>{comentario}</p>
       </div>
        
    </div>
  )
}

export default FormComent