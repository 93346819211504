import React from "react";
import "./Tes.css";
import img  from '../../img/testi1.png'
import img2 from '../../img/testi4.png'
import img3 from '../../img/testi3.png'
import img4 from '../../img/Recurso 145.png'
import img5 from '../../img/Recurso 146.png'
import img6 from '../../img/Recurso 147.png'



const Tes = () => {
  return (
    <div className="bgtes">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={img} class="  imgcarru" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img2} class=" imgcarru" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img3} class="imgcarru " alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img4} class="imgcarru " alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img5} class="imgcarru " alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img6} class="imgcarru " alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Tes;
