import React from "react";
import "./Mid.css";
import img from "../../img/Recurso 135.png";
import img2 from '../../img/Recurso 96.png'

import { Link } from "react-router-dom";

const Mid = () => {
  return (
    <div className="bgmid">
      <div className="d-flex container infg">
        <div className="textmid">
          <h4>&#8213; Con amor</h4>
          <h2 className="h2mid">Te acompaño en tu proceso </h2>
          <h3 className="h3mid">Psicoterapia online</h3>
          <p className="pmid">
            La psicoterapia online es completamente efectiva, el objetivo de la
            sesión es el profundo autoconocimiento, lo que te permite conocer y
            aceptar tus emociones, sanar tu historia personal, tu forma de
            percibir el mundo y así conectar con tu mejor versión una que tiene
            como base, el amor.
            <br></br>
            <br></br>A mi consulta llegan personas que como tú y como yo
            eligieron sanar y vivir su vida de otra manera, los casos más
            frecuentes que acompaño son:
            <div className="d-flex txt2 txt">
              <div className="mt-4">
                <p>- Duelo migratorio</p>
                <p>- Ansiedad</p>
                <p>- Crisis de pánico</p>
                <p>- Tristeza profunda</p>
              </div>
              <div className="m-4">
                <p>- Estrés</p>
                <p>- Crisis de identidad</p>
                <p>- Relaciones basadas en el apego</p>
                <p>- Expansión de conciencia.</p>
              </div>
            </div>
          </p>
        </div>
        <div className="md">
            <img className="stmid" src={img2} alt='' />
          <img className="cardimg" src={img} alt='' />
          <a className="btn  agendar" href='https://wa.link/qh1hll' target="_blank">Agendar aquí</a>
        </div>
      </div>
    </div>
  );
};

export default Mid;
