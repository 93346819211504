import React from 'react'
import Footer from '../../components/Footer/Footer'
import Form2 from '../../components/Form copy/Form2'
import Mid4citas from '../../components/mid4 copy/Mid4citas'
import Midcitas from '../../components/midcitas/Midcitas'
import Midcitas2 from '../../components/Midcitas2/Midcitas2'
import Midcitas3 from '../../components/Midcitas3/Midcitas3'
import Pagoinfo from '../../components/Pagoinfo/Pagoinfo'
import Top2 from '../../components/top2/Top2'

const Consulta = () => {
  return (
    <div>
        <Top2/>
        <Midcitas/>
        <Midcitas2/>
        <Mid4citas/>
        <Midcitas3/>
        <Form2/>
        <Pagoinfo/>
        <Footer/>
    </div>
  )
}

export default Consulta