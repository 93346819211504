import React from 'react'
import { Link } from 'react-router-dom';
import './RecursosCard.css'

const RecursosCard = (props) => {
    const { titulo, imagen_path, descripcion, audio_path, id    } = props;
  return (
    <div id="container ">
      <div className="mb-5">
        <h4 className='h4recursos mt-5 ms-3'>Meditacion Guiada</h4>
        <h3 className="h3recursos ms-3">{titulo}</h3>
        
        <div className="container">
        <Link to={`/detalles/${id}`}><img src={`https://back.lissetalbarracin.com/recursosAudios/${imagen_path}`} alt="Lago di Braies" className='imgrecursos' /></Link>
          
         
        </div>
      </div>
    </div>
  )
}

export default RecursosCard