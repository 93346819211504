import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Coments from '../../pages/Coment/Coments';
import { Context } from '../../Store';
import FormComent from '../FormComent/FormComent';
import './RecursosCard.css'

const CardDetalles = (props) => {
  const {comentarios, setComentarios} = useContext(Context)
    const { titulo, imagen_path, descripcion, audio_path    } = props;
    const filtrocoment = comentarios.filter((com)=>com.titulo==titulo)
    
    const soundEffect = new Audio();
soundEffect.autoplay = true;

soundEffect.src = audio_path

    const [com, setCom] = useState({
        nombre: "",
        titulo: titulo,
        comentario: ""
      });

    const handleChangeCom = (e) => {
        setCom({
          ...com,
          [e.target.name]: e.target.value,
        });
      };
      console.log(com)
      useEffect(()=>{
        obtenerComentarios();
        
      },{})
      
      useEffect(() => {
        window.addEventListener('touchstart', () => {
          document.getElementById('audio').muted = false
          document.getElementById('audio').play()
        })
      })


      useEffect(() => {
        function init() {
          var myAudio = document.getElementById("audio");
          myAudio.addEventListener('ended', loopAudio, false);
      }
      function loopAudio() {
          var myAudio = document.getElementById("audio");
          myAudio.play();
      }
      })
     
        
    


      const coment = com

      const enviarCom = async () => {
        
        const response = await axios.post(`https://back.lissetalbarracin.com/comentarios`, coment);
        console.log(response)
        console.log(response.data)
       
      }

      const obtenerComentarios = async () => {
        const response = await axios.get(`https://back.lissetalbarracin.com/comentarios`)
        const data = response.data
        setComentarios(response.data)
        console.log(response.data)
      }

      

    return (
      <div id="container ">
        <div className="mb-5">
          <h4 className='h4recursos mt-5 ms-3'>Meditacion Guiada</h4>
          <h3 className="h3recursos ms-3">{titulo}</h3>
          
          <div className="container">
          <img src={`https://back.lissetalbarracin.com/recursosAudios/${imagen_path}`} alt="Lago di Braies" className='imgrecursos' />
            
           <audio  controls preload='none' className='audiorecursos mt-5' >
              <source src={`https://back.lissetalbarracin.com/recursosAudios/${audio_path}`}  type='audio/ogg' />
              <source src={`https://back.lissetalbarracin.com/recursosAudios/${audio_path}`} codecs='1'  type='audio/wav' />
              <source src={`https://back.lissetalbarracin.com/recursosAudios/${audio_path}`}  type='audio/mp3' />
           </audio>
           <p className="precursos mt-3 mb-5">{descripcion}</p>
          </div>
        </div>
        <>
     <div className='container'>
    <div className='d-flex flex-wrap flex-column  '>
    {
      filtrocoment.length > 0? (
        filtrocoment.map(recurso => (
          <FormComent key={recurso.id} {...recurso} />
        ))
      ) : (<p className='text-center labelcom'> </p>)
    }
    </div>
    </div>
    
    </>
        <div>
        <form>
  <div class="form-group">
    <label className='labelcom' for="exampleFormControlInput1">Nombre y Apellido</label>
    <input onChange={handleChangeCom} type="text" class="form-control" name='nombre' id="exampleFormControlInput1" placeholder="Nombre y Apellido"/>
  </div>
  <div class="form-group">
  <label className='labelcom' for="exampleFormControlInput1">Titulo</label>
    <input onChange={handleChangeCom} readOnly   type="text" name='titulo' class="form-control" id="exampleFormControlInput1" placeholder={titulo}/>
  </div>
  
  
  <div class="form-group">
    <label className='labelcom' for="exampleFormControlTextarea1">Comentario</label>
    <textarea onChange={handleChangeCom} name='comentario' class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
  </div>
  <button className='btn labelcom ' onClick={enviarCom}>Enviar</button>
</form>
    </div>
      </div>
    )
}

export default CardDetalles