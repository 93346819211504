import React from 'react'
import Form from '../../components/Form/Form'
import Footer from '../../components/Footer/Footer'
import Clubtop from '../../components/Clubtop/Clubtop'
import Clubmid from '../../components/Clubmid/Clubmid'
import Clubmid2 from '../../components/Clubmid2/Clubmid2'
import Clubtop2 from '../../components/Clubtop2/Clubtop2'
import Clubtp from '../../components/Clubtp/Clubtp'
import Tes from '../../components/Tes/Tes'
import Info2 from '../../components/info2/Info2'
import Clubinscribir from '../../components/Clubinscribir/Clubinscribir'
import Pagoinfo from '../../components/Pagoinfo/Pagoinfo'

const Club = () => {
  return (
    <div>
        <Clubtp/>
        <Clubmid/>
        <Clubtop2/>
        <Tes/>
       
        <Clubmid2/>
        <Info2/>
        <Clubinscribir/>
        <Pagoinfo/>
        <Footer/>
    </div>
  )
}

export default Club