import React from 'react'
import './Encuentromid6.css'
import img from '../../../img/Recurso 135-.png'
import img2 from '../../../img/Recurso 151-.png'
import { Link } from 'react-router-dom'

const Encuentromid6 = () => {
  return (
    <div className='bgmid6encuentro'>
        <div className=' d-flex'>
            <div>
            <img className='imgmid6encuentro' src={img} alt=''/>
            </div>
        
            <a className='links' href='https://api.whatsapp.com/send?phone=+34617915662&text=Hola%20Liss%E2%9C%A8%20quiero%20inscribirme%20en%20el%20encuentro%20de%20consciencia' target="_blank"><img className='botonmid6encuentro' src={img2} alt=''/></a>
        </div>
        
    </div>
  )
}

export default Encuentromid6