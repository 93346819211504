import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import img from '../../img/whatsapp.png'


  
const Navbar = () => {
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light bg">
        <div className='container'>
  <Link className="navbar-brand" to="/">Lisset Albarracin</Link>
  <button ref={navButton} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  
    
  <div className="collapse navbar-collapse" id="navbarSupportedContent" ref={linksContainerRef}>
      <ul className="navbar-nav  navbar1">
        <Link className="nav-link" to="/" onClick={collapseNav}>Inicio</Link>
        <a onClick={collapseNav} className="nav-link " href="#sobre" >Sobre mi</a>
        <li className="nav-item dropdown" >
          <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Servicios
          </Link>
          <ul className="dropdown-menu ">
            <li><Link className="dropdown-item " to="/citas" onClick={collapseNav} >Consultas de psicoterapia</Link></li>
            <li><Link className="dropdown-item " to="/club"  onClick={collapseNav}>Club de Lectura</Link></li>
            <li><Link className="dropdown-item " to="/encuentro"  onClick={collapseNav}>Encuentro de consciencia</Link></li>
            
          </ul>
        </li>
        <li className="nav-item dropdown" >
          <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Podcast
          </Link>
          <ul className="dropdown-menu ">
            <li><a className="dropdown-item " href="https://www.youtube.com/@LissetAlbarracin-ExpansivaMent" onClick={collapseNav} >YouTube</a></li>
            <li><a className="dropdown-item " href="https://open.spotify.com/show/7r0vpIxQ1o6vLHwkrSVWal?si=mSf1spBISHu_5Y7jiUaqkw" onClick={collapseNav} >Spotify</a></li>
            
            
          </ul>
        </li>
        <a href='#footer' class="nav-link me-5" onClick={collapseNav} >Contacto</a>
        <Link className="nav-link" to="/recursos" onClick={collapseNav}>Espacio de meditación</Link>
      </ul>
      <ul className="navbar-nav ms-auto sm-icons " >
        <li className="nav-item"><a className="nav-link" href="https://www.facebook.com/profile.php?id=100066714128088" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook nbi" ></i></a></li>
        <li className="nav-item"><a className="nav-link" href="https://www.instagram.com/lisset_albarracin/" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram nbi"></i></a></li>
        <li className="nav-item"><a className="nav-link" href="#footer"><i className="bi bi-envelope nbi"></i></a></li>
        
       
    </ul>
    
    </div>
    <a href='https://api.whatsapp.com/send?phone=+34617915662&text=Hola%20Liss%E2%9C%A8' target="_blank">
        <img class ="boton-whatsapp " src="https://cdn-icons-png.flaticon.com/512/889/889154.png"/>
        </a>
    
  </div>
  
</nav>

  )
}



export default Navbar