import React from 'react'
import EncuentroComent from '../../components/Encuentro/EncuentroComent/EncuentroComent'
import EncuentroFooter from '../../components/Encuentro/EncuentroFooter/EncuentroFooter'
import Encuentromid from '../../components/Encuentro/Encuentromid/Encuentromid'
import Encuentromid2 from '../../components/Encuentro/Encuentromid2/Encuentromid2'
import Encuentromid3 from '../../components/Encuentro/Encuentromid3/Encuentromid3'
import Encuentromid4 from '../../components/Encuentro/Encuentromid4/Encuentromid4'
import Encuentromid5 from '../../components/Encuentro/Encuentromid5/Encuentromid5'
import Encuentromid6 from '../../components/Encuentro/Encuentromid6/Encuentromid6'
import Encuentropago from '../../components/Encuentro/EncuentroPago/Encuentropago'
import Encuentrotop from '../../components/Encuentro/EncuentroTOP/Encuentrotop'
import Encuentrotop2 from '../../components/Encuentro/Encuntrotop2/Encuentrotop2'
import Footer from '../../components/Footer/Footer'

const Encuentro = () => {
  return (
    <div>
        <Encuentrotop/>
        <Encuentrotop2/>
        <Encuentromid/>
        <Encuentromid2/>
        <Encuentromid3/>
        <Encuentromid4/>
        <EncuentroComent/>
        <Encuentromid5/>
        <Encuentromid6/>
        <Encuentropago/>
        <EncuentroFooter/>
        
    </div>
  )
}

export default Encuentro