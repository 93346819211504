import React from 'react'
import './Encuentromid3.css'

const Encuentromid3 = () => {
  return (
    <div className='bgmid3encuentro'>
        <h2 className='h2mid3encuentro'>¡Este espacio es para ti!</h2>
    </div>
  )
}

export default Encuentromid3