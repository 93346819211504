import React from 'react'
import './Encuentropago.css'
import img from '../../../img/Recurso 139-.png'

const Encuentropago = () => {
  return (
    <div className='bgpagoencuentro'>
        <img className='imgpagoencuentro' src={img} alt=''/>
    </div>
  )
}

export default Encuentropago