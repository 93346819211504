import React from 'react'
import './Top.css'
import img from '../../img/Recurso 115.png'

const Top = () => {
  return (
    <div className='bgtop'>
      <div className='textop'>
        <h2 className='h2top'>¡Este espacio es para ti!</h2>
        <p className='infotop'>Un espacio creado con amor que tiene como propósito acompañarte en tu proceso individual de autoconocimiento y expansión de conciencia. </p>
        </div>
        <h3 className='imgtop'>Bienvenidos</h3>
    </div>
  )
}

export default Top