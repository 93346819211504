import React from 'react'
import './Mid4citas.css'
import img  from '../../img/Recurso 143.png'
import img2 from '../../img/testi2.png'
import img3 from '../../img/Recurso 142.png'
import img4 from '../../img/Recurso 144.png'

const Mid4 = () => {
  return (
    <div className='bgmid4'>
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img} class="  imgcarru" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img2} class=" imgcarru" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img3} class="imgcarru " alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={img4} class="imgcarru " alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Mid4