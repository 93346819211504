import React from 'react'
import './Clubinscribir.css'
import img from '../../img/Boton Inscribete.png'
import img1 from '../../img/texto.png'
import { Link } from 'react-router-dom'

const Clubinscribir = () => {
  return (
    <div className='bannerinscribirse2'>
        <div className='alltextclub'>
           
            <h2 className='imgins2'>Un puente a la realidad</h2>
            <h2 className='texxt'>Cada sábado es un regalo</h2>
            <Link to='/registro'><img src={img} className='boton2' alt=''/></Link>
        </div>
    </div>
  )
}

export default Clubinscribir