import React from "react";
import "./Encuentrotop2.css";
import img from "../../../img/Recurso 113-.png";

const Encuentrotop2 = () => {
  return (
    <div className="bgtop2encuentro">
      <div className="container d-flex">
        <div>
          <img className="imgtop2encuentro" src={img} alt="" />
        </div>
        <div className="alltexttop2encuentro">
          <h2 className="h2top2encuentro ">Encuentros de consciencia.</h2>
          <h3 className="h3top2encuentro mt-1">Un espacio para la autoindagación</h3>
          <p className="ptop2encuentro mt-3">
            La auto indagación, en mi experiencia personal ha sido una
            herramienta transformadora que me ha permitido observar mis
            tendencias egoicas y acercarme con amor a mi esencia, reconociendo
            el Ser de una manera directa y amable.<br></br>
            <br></br> Los encuentros de consciencia brindan un ambiente seguro,
            amable y sin juicio. Te invitan a la observación honesta de tus
            experiencias, cuestionar tus creencias y a abrazar lo que sientes
            para conectar con la sensación de paz que es tu estado natural.
            <br></br>
            <br></br> En un sentido profundo, es un encuentro contigo mismo, un
            encuentro para expandir tu mente y tu corazón.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Encuentrotop2;
