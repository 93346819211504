import React from "react";
import "./Mid3.css";
import img from "../../img/Recurso 120.png";
import img2 from "../../img/Recurso 121.png";
import img3 from "../../img/Recurso 122.png";
import { Link } from "react-router-dom";

const Mid3 = () => {
  return (
    <div className="bgmid3">
      <h2 id="servicios" className="h2mid3">
        Espacios creados especialmente para ti
      </h2>
      <div className="d-flex justify-content-center allcard">
        <div class="d-flex flex-wrap cards">
          <div class="">
            <div class="card border-0 cardmid">
              <img src={img} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="cardtitle cardt">Encuentro de consciencia</h5>
                <p class="card-text cardtext">
                  En estos encuentros se promueve una experiencia de auto
                  indagación.
                </p>
                <Link to="/encuentro" class="btn boton3 mt-2">
                  Ver mas
                </Link>
              </div>
            </div>
          </div>
          <div class="">
            <div class="card border-0 cardmid">
              <img src={img2} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="cardtitle cardt">Club de lectura y meditación</h5>
                <p class="card-text cardtext">
                  Des hace dos anos compartimos nuestro club dedicando cada
                  sábado 1 hora a la lectura y meditación.
                </p>
                <Link to="/club" class="btn boton3 mt-2">
                  Ver mas
                </Link>
              </div>
            </div>
          </div>
          <div class="">
            <div class="card border-0 cardmid">
              <img src={img3} class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="cardtitle cardt">Psicoterapia Online</h5>
                <p class="card-text cardtext">
                  La psicoterapia online es completamente efectiva, el objetivo
                  de la sesión es el profundo autoconocimiento.
                </p>
                <Link to="/citas" class="btn boton3 mt-2">
                  Ver mas
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mid3;
