import React from 'react'
import './Encuentromid2.css'
import img from '../../../img/Recurso 118-.png'
import img2 from '../../../img/Recurso 119-.png'
import img3 from '../../../img/Recurso 120-.png'
import img4 from '../../../img/Recurso 122-.png'
import img5 from '../../../img/Recurso 123-.png'
import img6 from '../../../img/Recurso 124-.png'
import img7 from '../../../img/Recurso 140-.png'

const Encuentromid2 = () => {
  return (
    <div className='bgmid2encuentro'>
        <div>
            <img className='strellamid2encuentro' src={img7} alt/>
            <h2 className='h2mid2encuentro'>Algunos de nuestros encuentros: </h2>
        </div>
        <div className='d-flex flex-wrap allimgmidencuentro justify-content-center'>
            <img className='imgmid2encuentro' src={img} alt=''/>
            <img className='imgmid2encuentro' src={img2} alt=''/>
            <img className='imgmid2encuentro' src={img3} alt=''/>
            <img className='imgmid2encuentro' src={img4} alt=''/>
            <img className='imgmid2encuentro' src={img5} alt=''/>
            <img className='imgmid2encuentro' src={img6} alt=''/>
        </div>
    </div>
  )
}

export default Encuentromid2