import React from 'react'
import './Clubtp.css'

const Clubtp = () => {
  return (
    <div className='bgtp'>
    <div className='textoptp'>
    <h3 className='h3toptp'>Club de Lectura y meditación</h3>
    <h2 className='h2toptp'>Un puente a la realidad</h2>
    <h4 className='h4toptp'>Un espacio para leer, compartir y meditar</h4>
    </div>
    
</div>
  )
}

export default Clubtp