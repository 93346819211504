import React from "react";
import { Link } from "react-router-dom";
import "./Midcitas3.css";

const Midcitas3 = () => {
  return (
    
    <div className="bgmidcistas3">
      <div className="textmidcitas3">
        <h2 className="h2midcistas3">
          Puedes agendar tu cita através de WhatsApp
        </h2>
        <h3 className="h3midcistas3">Te espero con amor</h3>
        <a href='https://wa.link/qh1hll' target="_blank"><button className="btn botonmidcitas">Contactame aquí</button></a>
      </div>
    </div>
  );
};

export default Midcitas3;
