import React from 'react'
import Encuentrores from '../../components/Encuentro/Encuentrores/Encuentrores'
import Footer from '../../components/Footer/Footer'
import InfPago2 from '../../components/infopago2/InfPago2'

const ResEnc = () => {
  return (
    <div>
        <Encuentrores/>
        <InfPago2/>
        <Footer/>
    </div>
  )
}

export default ResEnc