import React from "react";
import "./Encuentromid.css";

const Encuentromid = () => {
  return (
    <div className="bgmidencuentro">
        
      <p className="pmidencuentro container">
        Te invito a vivir una experiencia profunda, en la que puedas conectar
        con tu Ser único, en la que te puedas dar cuenta que la forma en la que
        te piensas produce un efecto en tus experiencias y esto, es
        absolutamente transformador
      </p>
    </div>
  );
};

export default Encuentromid;
