import React from 'react'
import './Top2.css'
import img from '../../img/Recurso 115.png'

const Top2 = () => {
  return (
    <div className='bgtop22'>
    <div className='textop22'>
      <h2 className='h2top22 mb-3'>¡Este espacio es para ti!</h2>
      <p className='infotop22'>Un espacio creado con amor que tiene como propósito acompañarte en tu proceso individual de autoconocimiento y expansión de conciencia. </p>
      </div>
      
  </div>
  )
}

export default Top2