import React from 'react'
import './EncuentroFooter.css'
import img from '../../../img/FB footer.png'
import img1 from '../../../img/Insta footer.png'
import img2 from '../../../img/Youtube footer.png'

const EncuentroFooter = () => {
  return (
    <div className='bgencuentrofooter'>
        <div className='footerbg asd' id='footer'>
        <div className='container  todo '>
        <div >
            <h2 className='nametitle'>Lisset Albarracin</h2>
            <h3 className='h3info'><i className="bi bi-geo-alt mbi"></i>Madrid</h3>
            <h3 className='h3info'><i className="bi bi-whatsapp mbi"></i>+34 617 91 56 62</h3>
            <h3 className='h3info email ' ><i className="bi bi-envelope mbi em"></i>info@lissetalbarracin.com</h3>

        </div>
        <div className='about'>
            <h3 className='h3info'>Psicóloga - Psicoterapeuta</h3>
            <h3 className='h3info'>Facilitadora en expansión de conciencia</h3>
            <h3 className='h3info'>Psicoterapia Online</h3>
            <div>
                <a href="https://www.facebook.com/profile.php?id=100066714128088" target="_blank" rel="noopener noreferrer"><img className='imgfooter' src={img} alt=''/></a>
                <a href="https://www.instagram.com/lisset_albarracin/" target="_blank" rel="noopener noreferrer"><img className='imgfooter' src={img1} alt=''/> </a>
                <img className='imgfooter' src={img2} alt=''/>
            </div>
        </div>
        </div>
        <h4 className='right h3info'>Todos los derechos reservados Lisset Albarracin 2022</h4>
    </div>
    </div>
  )
}

export default EncuentroFooter