import React from 'react'
import Footer from '../../components/Footer/Footer'
import InfPago2 from '../../components/infopago2/InfPago2'

import ResForm from '../../components/ResForm/ResForm'


const Inscribirse = () => {
  return (
    <div>
        
        <ResForm/>
        <InfPago2/>
        <Footer/>
       
    </div>
  )
}

export default Inscribirse